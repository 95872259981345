import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";

export async function useVueRecaptcha(): Promise<IOurRecaptchaComposition> {
  const { vueApp } = useNuxtApp();

  const config = useRuntimeConfig();

  vueApp.use(VueReCaptcha, {
    siteKey: config.public.RECAPTCHA_SITE_KEY as string,
    loaderOptions: { autoHideBadge: false },
  });

  const recaptchInstance = useReCaptcha();

  if (recaptchInstance === undefined) {
    throw new Error("Recaptcha instance is undefined");
  }

  await recaptchInstance.recaptchaLoaded();

  return {
    execute: async (action) => {
      return await recaptchInstance.executeRecaptcha(action);
    },
  };
}

export interface IOurRecaptchaComposition {
  execute: (action: string) => Promise<string>;
}
